import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { ChakraProvider, Spinner, Center } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    fonts: {
        heading: 'Roboto',
        body: 'Roboto'
    }
});

const Chat = lazy(() => import('./Chat'));
const Landing = lazy(() => import('./Landing'));
const Generate = lazy(() => import('./Generate'));
const MyTrips = lazy(() => import('./MyTrips'));
const Itinerary = lazy(() => import('./Itinerary'));
const Login = lazy(() => import('./Login'));
const Signup = lazy(() => import('./Signup'));
const Profile = lazy(() => import('./Profile'));
const Privacy = lazy(() => import('./Privacy'));
const Terms = lazy(() => import('./Terms'));
const Admin = lazy(() => import('./Admin'));
const Carbon = lazy(() => import('./Carbon'));
const Forgot = lazy(() => import('./Forgot'));
const Reset = lazy(() => import('./Reset'));

export const API_URL = import.meta.env.DEV ? 'http://localhost:3000' : 'https://mytrip.city';

export const LoadingSpinner = () => (
    <Center h="100vh">
        <Spinner size="xl" color="blue.500" thickness="4px" />
    </Center>
);

export const App = () => {
    useEffect(() => {
        ReactGA.initialize('G-E5XZ9W8ZWN');
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }, []);

    return (
        <ChakraProvider theme={theme}>
            <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
                <Router
                    future={{
                        v7_startTransition: true,
                        v7_relativeSplatPath: true
                    }}
                >
                    <Suspense fallback={<LoadingSpinner />}>
                        <Routes>
                            <Route path="/" element={<Landing />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/generate" element={<Generate />} />
                            <Route path="/generate/:dest" element={<Generate />} />
                            <Route path="/mytrips" element={<MyTrips />} />
                            <Route path="/app" element={<MyTrips />} />
                            <Route path="/itinerary/:id" element={<Itinerary />} />
                            <Route path="/chat/:id" element={<Chat />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/terms" element={<Terms />} />
                            <Route path="/admin" element={<Admin />} />
                            <Route path="/carbon" element={<Carbon />} />
                            <Route path="/carbon/:dest" element={<Carbon />} />
                            <Route path="/forgot" element={<Forgot />} />
                            <Route path="/reset-password/:token" element={<Reset />} />
                            <Route path="*" element={<Landing />} />
                        </Routes>
                    </Suspense>
                </Router>
            </GoogleOAuthProvider>
        </ChakraProvider>
    );
};
