import ReactDOM from 'react-dom/client';
import { App } from './App';
import * as Sentry from '@sentry/react';
import './main.css';

Sentry.init({
    dsn: 'https://6e41ddd0786b4ecb28ee1deb650f4070@o383246.ingest.us.sentry.io/4508221951967232',
    integrations: [Sentry.browserTracingIntegration()],
    enabled: process.env.NODE_ENV !== 'development'
});

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
